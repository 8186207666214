import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const form = useRef();
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_pfr40sq', 'template_okqk7bl', form.current, '1c9JFhuOkvNtYqBR9')
            .then((result) => {
                setMessage('Email envoyé avec succès');
                console.log(result.text);
            }, (error) => {
                setMessage('Erreur durant l\'envoi');
                console.log(error.text);
            });
    };

    return (
        <div className="container mt-5">
            {message && <p className="alert alert-success">{message}</p>} {}
            <form ref={form} onSubmit={sendEmail}>
                <div className="mb-3">

                    <label className="form-label">Nom</label>
                    <input className="form-control" type="text" name="user_name"/>
                </div>

                <div className="mb-3">

                    <label className="form-label">Mail</label>
                    <input className="form-control" type="email" name="user_email"/>
                </div>

                <div className="mb-3">

                    <label className="form-label">Message</label>
                    <textarea className="form-control" name="message"/>
                </div>
                <input className="btn btn-success" type="submit" value="Envoyer"/>
            </form>
        </div>
    );
};

export default ContactForm;
