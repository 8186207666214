import React from 'react';
import LoginForm from "../components/LoginForm/LoginForm";

const Login = () => {
    return (
        <div className="Login-container">
            <h1>Connexion</h1>
           <LoginForm/>
        </div>
    );
};

export default Login;