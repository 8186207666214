import axios from 'axios';

const apiService = axios.create({
    baseURL: '',
});

const fetchFromURL = async (url) => {
    try {
        const response = await apiService.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export { fetchFromURL };
