import React from 'react';
import {Link, useNavigate} from "react-router-dom";

const Header = () => {
    const isLogin = localStorage.getItem('token');
    const navigate = useNavigate();

    function disconnect() {
        localStorage.removeItem('token');
        navigate('/');
    }

    function loginOutput() {
        if (isLogin) {
            return (
                <a className="m-4" onClick={disconnect}>Se déconnecter</a>
            )
        } else {
            return (
                <Link className="self-center m-4" to="/">Se connecter</Link>
            )
        }
    }

    return (
        <div>
            <nav className="">
                <ul className="flex">
                    {isLogin ?
                        <li className="m-3">
                            <Link className="m-3" to="/tasks">Accueil</Link>
                            <Link to="/contact">Contact</Link>
                            {loginOutput()}
                        </li>
                        :
                        <li className="m-3">

                            <Link className="m-3" to="/">Accueil</Link>
                        </li>

                    }
                </ul>
            </nav>
        </div>
    )
        ;
};

export default Header;