import {useState} from 'react';

const Comments = () => {
    const [comments, setComments] = useState(
        JSON.parse(localStorage.getItem('comments')) ?? []
    );
    const [inputValue, setInputValue] = useState('');

    const handleSubmit = (e) => {
        if (inputValue) {
            const newComments = [...comments, inputValue];
            setComments(newComments);
            localStorage.setItem('comments', JSON.stringify(newComments));
        }
    };

    const  handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleDelete = (index) => (e) => {
        e.preventDefault();
        const storedComments = JSON.parse(localStorage.getItem("comments"));
        storedComments.splice(index, 1);
        localStorage.setItem("comments", JSON.stringify(storedComments));
        setComments(storedComments);
    }

    return (
        <div>
            <h2>Commentaires</h2>
            <input  type="text"  value={inputValue} onChange={handleChange} />
            <button onClick={handleSubmit}>Envoyer</button>
            <ul>
                {comments.map((comment, index) => (
                        <li key={index}>{comment}
                            <span onClick={handleDelete(index)}>
                                <img src="/img/trash.svg" alt="poubelle"/>
                            </span>
                        </li>
                ))}
            </ul>
        </div>
    );
};

export default Comments;