import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import Login from "./pages/Login";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import "minireset.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Tasks from "./pages/Tasks";
import Task from "./pages/Task";
import Contact from "./pages/Contact";
import Header from "./components/Header/Header";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <Header/>
            <Routes>
                <Route activeClassName="active" exact path="/" element={<Login/>}></Route>
                <Route exact path="/tasks" element={<Tasks/>}></Route>
                <Route exact path="/tasks/:id" element={<Task/>}></Route>
                <Route exact path="/contact" element={<Contact/>}></Route>
            </Routes>

        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
