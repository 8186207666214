import React from 'react';
import {useNavigate} from "react-router-dom";

const LoginForm = () => {
    const navigate = useNavigate();

    const onSubmit = (e) => {
        e.preventDefault()
        const { pseudo, password } = e.target.elements

        fetch('https://dummyjson.com/auth/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: pseudo.value,
                password: password.value,
            })
        })
            .then(res => res.json())
            .then(
                (res) => {
                    navigate('/tasks');
                    localStorage.setItem('token', JSON.stringify(res.token));
                    localStorage.setItem('usermail', res.email);
                    localStorage.setItem('username', res.username);
                }
            );
    }

    return (
        <div>
            <div className="container mt-5">
                <form onSubmit={onSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="pseudo">
                            Pseudo
                        </label>
                        <input className="form-control" type="text" id="pseudo" required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="password">
                            Mot de passe
                        </label>
                        <input className="form-control" type="password" id="password" required />
                    </div>
                    <button className="btn btn-success" type="submit">
                        Se connecter
                    </button>
                </form>
            </div>

        </div>
    );
};

export default LoginForm;