import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Comment from "../components/Comments/Comments";
import Header from "../components/Header/Header";

const Task = () => {
    const { id } = useParams();
    const [task, setTask] = useState({ todo: '', completed: false });

    const navigate = useNavigate();

    useEffect(() => {
        const tasks = JSON.parse(localStorage.getItem('tasks')) || [];
        const taskToEdit = tasks[id - 1] || { todo: '', completed: false };
        setTask(taskToEdit);
    }, [id]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;

        setTask((prevTask) => ({
            ...prevTask,
            [name]: newValue,
        }));
    };

    const handleSubmit = () => {
        const tasks = JSON.parse(localStorage.getItem('tasks')) || [];

        tasks[id - 1] = task;

        localStorage.setItem('tasks', JSON.stringify(tasks));
        navigate('/tasks');
    };

    return (
        <div>
            <h1>Tâche n°{id}</h1>
            <Form className="m-4">
                <Row className="row-cols-lg-auto g-3 align-items-center">
                    <Col>
                        <Label className="visually-hidden" for="todo">
                            To-do
                        </Label>
                        <Input
                            id="todo"
                            name="todo"
                            placeholder="Titre"
                            type="text"
                            value={task.todo}
                            onChange={handleChange}
                        />

                    </Col>
                    <Col>
                        <FormGroup check>
                            <Input
                                id="completion"
                                name="completed"
                                type="checkbox"
                                checked={task.completed}
                                onChange={handleChange}
                            />
                            <Label check for="completion">
                                Fait ?
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <Button onClick={handleSubmit}>Modifier</Button>
                    </Col>
                </Row>
            </Form>

            <Comment/>
        </div>
    );
};

export default Task;
