import React, {useEffect, useState} from 'react';
import {fetchFromURL} from "../services/apiService";
import {Button, Col, Form, Input, Label, Row, Spinner} from "reactstrap";
import AddTask from "../components/AddTask/AddTask";
import {Link, useNavigate} from "react-router-dom";

const Tasks = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [tasks, setTasks] = useState([]);


    useEffect(() => {
        if (localStorage.getItem("tasks") === null) {
            fetchFromURL("https://dummyjson.com/todos?limit=10").then(
                (data) => {
                    localStorage.setItem("tasks", JSON.stringify(data.todos));
                    setTasks(JSON.parse(localStorage.getItem("tasks")));
                    setIsLoading(false);
                }).catch((error) => {
                    console.log(error);
                }
            )
        } else {
            setTasks(JSON.parse(localStorage.getItem("tasks")));
            setIsLoading(false);
        }
    }, []);

    const handleDelete = (id) => (e) => {
        e.preventDefault();
        const storedTask = JSON.parse(localStorage.getItem("tasks"));

        const arrayWithoutTask = [];

        for (let i = 0; i < storedTask.length; i++) {
            if (storedTask[i].id !== id) {
                arrayWithoutTask.push(storedTask[i]);
            }
        }

        localStorage.setItem("tasks", JSON.stringify(arrayWithoutTask));
        setTasks(JSON.parse(localStorage.getItem("tasks")))
    }

    function imageOutput(status) {
        if (status) {
            return <img src="/img/check.svg" alt="done"/>
        } else {
            return <img src="/img/x.svg" alt="done"/>
        }
    }

    // ADD TASK

    const allTasks = JSON.parse(localStorage.getItem('tasks'));
    const initialState = allTasks ? allTasks : [];


    const [inputValue, setInputValue] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue) {
            const newTask =
                {
                    id: tasks.length + 1,
                    todo: inputValue,
                    completed: false,
                };

            setTasks([...tasks, newTask]);

            if (!allTasks) {
                setTasks([newTask]);
                localStorage.setItem('tasks', JSON.stringify([newTask]));
            } else
                setTasks([...allTasks, newTask]);
            localStorage.setItem('tasks', JSON.stringify([...allTasks, newTask]));
        }
    };

    const  handleChange = (event) => {
        setInputValue(event.target.value);
    };


    return (
        <div className="container">
            <h1>Tâches</h1>
            {isLoading ? (
                <Spinner
                    color="dark"
                    style={{
                        height: '3rem',
                        width: '3rem',
                    }
                    }
                >
                    Loading...
                </Spinner>
            ) : (
                <div>


                    <Form className="m-4">
                        <Row className="row-cols-lg-auto g-3 align-items-center">
                            <Col>
                                <Label
                                    className="visually-hidden"
                                    for="taskTitle"
                                >
                                    Titre
                                </Label>
                                <Input
                                    id="taskTitle"
                                    name="title"
                                    placeholder="Titre"
                                    type="text"
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col>
                                <Button onClick={handleSubmit}>
                                    Ajouter une nouvelle tâche
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Tâche</th>
                            <th scope="col">Statut</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tasks.map((task, index) => (
                            <tr key={index}>
                                <th scope="row">{task.id}</th>
                                <td>{task.todo}</td>
                                <td>{imageOutput(task.completed)}</td>
                                <td>
                                    <button onClick={handleDelete(task.id)}>Supprimer</button>

                                    <Link to={`${task.id}`}>
                                        <button>Modifier</button>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

        </div>
    );
};

export default Tasks;